@use 'abstracts/functions';
@use 'abstracts/variables';

a {
  align-items: center;
  color: map-get(variables.$colors, 'primary');
  cursor: pointer;
  display: inline-flex;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  text-decoration: none;

  svg-icon {
    font-size: 24px !important; /* stylelint-disable-line declaration-no-important */

    &:first-child {
      margin-right: 10px;
    }

    &:last-child {
      margin-left: 10px;
    }
  }

  &.disabled {
    color: map-get(variables.$colors, 'textDisabled');
    pointer-events: none;

    svg-icon {
      color: inherit;
    }
  }

  &:active {
    color: map-get(variables.$colors, 'primary200');

    svg-icon {
      color: inherit;
    }
  }
}
