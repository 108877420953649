@use 'abstracts/functions';
@use 'abstracts/variables';

button {
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 18px 40px;
  min-height: 64px;
  border-radius: 34px;
  border: 1px solid transparent;
  font-size: 16px;
  line-height: 24px;

  svg-icon {
    &:not(.custom-font-size) {
      font-size: 32px !important; /* stylelint-disable-line declaration-no-important */
    }

    &:first-child {
      margin-right: 24px;
    }

    &:last-child {
      margin-left: 24px;
    }
  }

  &.primary {
    background-color: map-get(variables.$colors, 'primary');
    color: map-get(variables.$colors, 'white100');

    &:disabled {
      background-color: map-get(variables.$colors, 'backgroundDisabled');
      color: map-get(variables.$colors, 'textDisabled');
      pointer-events: none;
    }

    &:active {
      background-color: map-get(variables.$colors, 'primary200');
    }
  }

  &.secondary {
    background-color: transparent;
    border-color: currentColor;
    color: map-get(variables.$colors, 'primary');

    &:disabled {
      color: map-get(variables.$colors, 'textDisabled');
      pointer-events: none;
    }

    &:active {
      background-color: map-get(variables.$colors, 'white100');
    }
  }

  &.white {
    background-color: map-get(variables.$colors, 'white100');
    border-color: map-get(variables.$colors, 'white100');
    color: map-get(variables.$colors, 'primary');

    &.with-border {
      border-color: map-get(variables.$colors, 'primary');
    }

    &:disabled {
      color: map-get(variables.$colors, 'textDisabled');
      pointer-events: none;
    }

    &:active {
      background-color: map-get(variables.$colors, 'background25');
    }
  }

  &.large {
    font-size: 24px;
    line-height: 28px;
    padding: 14px 40px;
  }

  &.small {
    padding: 8px 24px;
    min-height: 48px;

    svg-icon {
      &:not(.custom-font-size) {
        font-size: 24px !important; /* stylelint-disable-line declaration-no-important */
      }

      &:first-child {
        margin-right: 16px;
      }

      &:last-child {
        margin-left: 16px;
      }
    }
  }

  &.tiny {
    min-height: 32px;
    padding: 4px 16px;

    /* stylelint-disable-next-line no-descending-specificity */
    svg-icon {
      &:not(.custom-font-size) {
        font-size: 16px !important; /* stylelint-disable-line declaration-no-important */
      }

      &:first-child {
        margin-right: 12px;
      }

      &:last-child {
        margin-left: 12px;
      }
    }
  }
}
