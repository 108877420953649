@use 'abstracts/variables';

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background: map-get(variables.$colors, 'background50');
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: map-get(variables.$colors, 'secondary10');
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: map-get(variables.$colors, 'secondary');
}
