@use 'abstracts/variables';

@each $name, $colors in variables.$colors {
  @each $value in $colors {
    .color-#{'' + $name} {
      color: $value !important; /* stylelint-disable-line declaration-no-important */
    }

    .background-#{'' + $name} {
      background: $value !important; /* stylelint-disable-line declaration-no-important */
    }
  }
}
