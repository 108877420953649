@use 'abstracts/functions';
@use 'abstracts/variables';
@use 'base/mixins';

span.tag {
  display: inline-flex;
  align-items: center;
  background-color: map-get(variables.$colors, 'backgroundTag');
  border: 1px solid transparent;
  color: map-get(variables.$colors, 'secondary');
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  gap: variables.$base-spacing;
  height: 48px;
  line-height: 24px;
  padding: 6px 16px !important; /* stylelint-disable-line declaration-no-important */
  border-radius: 40px;

  &.disabled {
    background-color: map-get(variables.$colors, 'backgroundDisabled');
    color: map-get(variables.$colors, 'textDisabled');
    pointer-events: none;
  }

  &.pressed {
    border: 1px solid map-get(variables.$colors, 'secondary');
  }

  &.small {
    font-size: 14px;
    height: 32px;
    line-height: 20px;
  }
}

@include mixins.media-breakpoint-up(sm) {
  span.tag {
    padding: 0 functions.spacing(3);
  }
}
