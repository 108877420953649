// Custom fonts
@font-face {
  font-family: 'NotoSans';
  font-style: normal;
  font-weight: 400;
  src: url('../../assets/fonts/NotoSans-Regular.woff2') format('woff2');
}
@font-face {
  font-family: 'NotoSans';
  font-style: normal;
  font-weight: 500;
  src: url('../../assets/fonts/NotoSans-Medium.woff2') format('woff2');
}
@font-face {
  font-family: 'NotoSans';
  font-style: normal;
  font-weight: 600;
  src: url('../../assets/fonts/NotoSans-SemiBold.woff2') format('woff2');
}
@font-face {
  font-family: 'NotoSans';
  font-style: normal;
  font-weight: 700;
  src: url('../../assets/fonts/NotoSans-Bold.woff2') format('woff2');
}

// Italic
@font-face {
  font-family: 'NotoSans';
  font-style: italic;
  font-weight: 400;
  src: url('../../assets/fonts/NotoSans-Italic.woff2') format('woff2');
}
@font-face {
  font-family: 'NotoSans';
  font-style: italic;
  font-weight: 500;
  src: url('../../assets/fonts/NotoSans-MediumItalic.woff2') format('woff2');
}
@font-face {
  font-family: 'NotoSans';
  font-style: italic;
  font-weight: 600;
  src: url('../../assets/fonts/NotoSans-SemiBoldItalic.woff2') format('woff2');
}
@font-face {
  font-family: 'NotoSans';
  font-style: italic;
  font-weight: 700;
  src: url('../../assets/fonts/NotoSans-BoldItalic.woff2') format('woff2');
}
