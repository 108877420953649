@use 'abstracts/variables';

@each $font in variables.$font-config {
  @each $font-weight in variables.$font-weights {
    $font-name: map-get($font, 'name');
    $font-weight-name: map-get($font-weight, 'name');
    $default-font-weight-name: map-get($font, 'default-font-weight');

    .#{$font-name}-#{$font-weight-name} {
      font-size: map-get($font, 'font-size');
      font-weight: map-get($font-weight, 'font-weight');
      line-height: map-get($font, 'line-height');
    }

    // generate italic fonts
    .#{$font-name}-#{$font-weight-name}-italic {
      font-size: map-get($font, 'font-size');
      font-style: italic;
      font-weight: map-get($font-weight, 'font-weight');
      line-height: map-get($font, 'line-height');
    }

    // generate font name with the default font-weight so it doesn't postfix
    @if $font-weight-name == $default-font-weight-name {
      .#{$font-name} {
        font-size: map-get($font, 'font-size');
        font-weight: map-get($font-weight, 'font-weight');
        line-height: map-get($font, 'line-height');
      }
    }

    // italic - generate font name with the default font-weight so it doesn't postfix
    @if $font-weight-name == $default-font-weight-name {
      .#{$font-name}-italic {
        font-size: map-get($font, 'font-size');
        font-style: italic;
        font-weight: map-get($font-weight, 'font-weight');
        line-height: map-get($font, 'line-height');
      }
    }
  }
}
