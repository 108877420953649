@use 'abstracts/functions';
@use 'abstracts/variables';

.input-container {
  background: map-get(variables.$colors, 'background50');
  border: 1px solid transparent;
  border-radius: 32px;
  min-height: 56px;
  gap: functions.spacing(2);
  color: map-get(variables.$colors, 'primary');

  &.light {
    background: map-get(variables.$colors, 'white100');
  }

  &.disabled {
    background: map-get(variables.$colors, 'backgroundDisabled');
  }

  &:not(.disabled):not(.error):hover,
  &:not(.disabled):not(.error):focus {
    border: 1px solid map-get(variables.$colors, 'primary');
  }

  &.error {
    background: map-get(variables.$colors, 'error10');
    color: map-get(variables.$colors, 'error');

    &:hover,
    &f:focus {
      border: 1px solid map-get(variables.$colors, 'error');
    }
  }

  input,
  textarea {
    background: none;
    border: none;
    caret-color: map-get(variables.$colors, 'primary');
    color: map-get(variables.$colors, 'primary');
    outline: none;

    &::placeholder {
      color: map-get(variables.$colors, 'secondary');
      opacity: 0.64;
    }
  }

  &.secondary input,
  &.secondary textarea {
    font-style: italic;
    font-weight: 600;
    color: map-get(variables.$colors, 'secondary');
    caret-color: map-get(variables.$colors, 'secondary');

    &::placeholder {
      font-weight: normal;
    }
  }

  &.error input,
  &.error textarea {
    color: map-get(variables.$colors, 'error');
    caret-color: map-get(variables.$colors, 'error');

    &::placeholder {
      color: map-get(variables.$colors, 'error');
    }
  }

  &.disabled input,
  &.disabled textarea {
    color: map-get(variables.$colors, 'textDisabled');

    &::placeholder {
      color: map-get(variables.$colors, 'textDisabled');
    }
  }

  .clear-button {
    background: none;
    border: none;
    padding: 0;
    font-size: 12px;
    color: map-get(variables.$colors, 'primary');
  }

  .value-length {
    opacity: 0.64;
  }

  svg-icon {
    color: map-get(variables.$colors, 'secondary') !important; /* stylelint-disable-line declaration-no-important */
  }

  &.error svg-icon {
    color: map-get(variables.$colors, 'error') !important; /* stylelint-disable-line declaration-no-important */
  }

  &.disabled svg-icon {
    color: map-get(variables.$colors, 'textDisabled') !important; /* stylelint-disable-line declaration-no-important */
  }
}
