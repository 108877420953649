@use 'abstracts/variables';
@use 'abstracts/functions';

.dialog-backdrop {
  background: rgba(208 209 225 / 90%);
  backdrop-filter: blur(5px);
}

.dialog-container {
  background-color: map-get(variables.$colors, 'white100');
  box-shadow: 0 0 56px rgb(2 6 91 / 12%);
  border-radius: 28px;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  padding: functions.spacing(5);
}

.dialog-title {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: functions.spacing(3);
}

.dialog-content {
  flex: 1;
  overflow: auto;
}
