@use 'abstracts/variables';

.separator {
  width: 100%;
  height: 2px;
  background: map-get(variables.$colors, 'background');
  border-radius: 2px;
  margin: 10px 0;

  &.v1 {
    background: map-get(variables.$colors, 'background50');
  }

  &.vertical {
    height: 48px;
    width: 2px;
    margin: 0 10px;
  }
}
