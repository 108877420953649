$base-spacing: 0.5rem;
$colors: (
  primary: #081cd2,
  primary200: #1322b2,
  secondary: #02065b,
  secondary40: #9697bb,
  secondary10: #d0d1e1,
  error: #fc4c3f,
  error10: #f9ebec,
  success: #52ae77,
  success10: #d6f0e1,
  warningText: #c68200,
  warning: #ffc500,
  warning10: #fff4b9,
  textDisabled: #8d8d8d,
  background: #d8dce8,
  background50: #eef0f7,
  background25: #f8f8fb,
  backgroundTag: #e9d8ef,
  backgroundGradient: linear-gradient(90deg, #eff0f8 73.26%, rgb(239 240 248 / 40%) 100.01%),
  backgroundDisabled: #ebebeb,
  black0: #000,
  white100: #fff,
  ideating: #fff386,
  planing: #98e6ff,
  literature: #f0e6d6,
  experimenting: #ff9e2d,
  analysing: #94d8af,
  resultsPublished: #fca1c1,
  labelSign: #dfbcec,
  upcoming: #89e3ff,
  ongoing: #ffea2d,
  past: #ffc500,
  collaboration: #7ce7c0,
);
$icon-sizes: (
  default: 16px,
);
$font-weights: (
  (
    name: 'regular',
    font-weight: 400,
  ),
  (
    name: 'medium',
    font-weight: 500,
  ),
  (
    name: 'semibold',
    font-weight: 600,
  ),
  (
    name: 'bold',
    font-weight: 700,
  )
);
$font-config: (
  (
    name: 'title-h1',
    font-size: 28px,
    line-height: 34px,
    default-font-weight: 'bold',
  ),
  (
    name: 'title-h2',
    font-size: 24px,
    line-height: 28px,
    default-font-weight: 'bold',
  ),
  (
    name: 'title-h3',
    font-size: 20px,
    line-height: 24px,
    default-font-weight: 'bold',
  ),
  (
    name: 'text-large-button',
    font-size: 24px,
    line-height: 28px,
    default-font-weight: 'bold',
  ),
  (
    name: 'text-large',
    font-size: 18px,
    line-height: 24px,
    default-font-weight: 'regular',
  ),
  (
    name: 'text-normal',
    font-size: 16px,
    line-height: 24px,
    default-font-weight: 'regular',
  ),
  (
    name: 'text-small',
    font-size: 14px,
    line-height: 20px,
    default-font-weight: 'regular',
  ),
  (
    name: 'text-tiny',
    font-size: 12px,
    line-height: 16px,
    default-font-weight: 'regular',
  )
);
