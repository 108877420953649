// Base
@import '@angular/cdk/overlay-prebuilt.css';

// Base
@import 'base/bootstrap';
@import 'base/base';
@import 'base/colors';
@import 'base/fonts';
@import 'base/icon-sizes';
@import 'base/typography';
@import 'base/layout';
@import 'base/shadows';
@import 'base/scrollbar';

// Components
@import 'components/dialog';
@import 'components/buttons';
@import 'components/tags';
@import 'components/text-link';
@import 'components/inputs';
@import 'components/separator';

// bootstrap messes uses `vertical-align: middle` which messes up svg-icon positioning / boxing
// https://github.com/twbs/bootstrap/blob/main/scss/_reboot.scss#L341
svg-icon {
  // stylelint-disable-next-line
  display: inline-flex !important;
}

html,
body {
  width: 100%;
  height: 100%;
}

body {
  /* stylelint-disable-next-line scss/at-extend-no-missing-placeholder */
  @extend .text-normal-regular;

  font-family: 'NotoSans', sans-serif;
}
